<template>
  <div class="quiz-container">
    <h1>{{ title }}</h1>

    <!-- Check if there is a final state to show the result, otherwise show questions -->
    <div v-if="finalState === null">
      <!-- Display the current question -->
      <div class="question-section">
        <h2>{{ currentQuestion.question }}</h2>
        <img :src="currentQuestionImage" class="question-image" alt="Question image" v-if="currentQuestion.image" />
      </div>

      <!-- Display answers for the current question as buttons -->
      <div class="answers-section">
        <ul class="answers-list">
          <button v-for="(answer, index) in currentQuestion.answers" :key="index" @click="answerSelected(answer)"
            class="answer-button">
            {{ answer.text }}
          </button>
        </ul>
      </div>
    </div>

    <!-- Display the final state after answering all questions -->
    <div v-else class="result-section">
      <img :src="finalStateImage" class="result-image" alt="Result image" v-if="finalState.image" />
      <h2>{{ finalState.title }}</h2>
      <p class="finaleStateDescription">{{ finalState.description }}</p>

      <p class="finalStateDescription">Egal welchen Mamster du gezogen hast: Dein Mamster mag dich, genau so, wie du
        bist!</p>

      <p>
        <a class="win" href="#/win">🤩 Du hast einen <strong>Mamster-Gutschein</strong> gewonnen!</a>
      </p>
    </div>

    <div v-if="finalState === null" class="question-counter">
      Frage {{ currentQuestionNumber }} von {{ totalQuestions }}
    </div>

    
    <p><a href="#/mamsterslots">Spiele 🎰 Einarmiger Mamster</a></p>
    <span class="madewith">Made with ❤️ by your favorite Mamster</span>
  </div>
  <br>

</template>


<script>
import quizData from '../../quiz.json'

export default {
  data() {
    return {
      title: quizData.title,
      questions: quizData.questions,
      currentQuestionIndex: 0,
      scores: {},
      endStates: quizData.endStates,
      finalState: null
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    currentQuestionNumber() {
      return this.currentQuestionIndex + 1;
    },
    totalQuestions() {
      return this.questions.length;
    },
    currentQuestionImage() {
      // Ensure the image path in quiz.json is relative to the src/assets directory
      return require(`@/assets/${this.currentQuestion.image}`);
    },
    finalStateImage() {
      // Loads the image for the final state once it's determined
      return this.finalState ? require(`@/assets/${this.finalState.image}`) : '';
    }
  },
  methods: {
    answerSelected(answer) {
      // Update scores based on the selected answer
      Object.entries(answer.points).forEach(([endState, points]) => {
        if (!this.scores[endState]) this.scores[endState] = 0;
        this.scores[endState] += points;
      });

      // Proceed to next question or determine the final state if it was the last question
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
      } else {
        this.determineFinalState();
      }
    },
    determineFinalState() {
      // Determine the final state based on the highest score
      const maxScore = Math.max(...Object.values(this.scores));
      const finalStateId = Object.keys(this.scores).find(key => this.scores[key] === maxScore);
      this.finalState = this.endStates.find(state => state.id === finalStateId);
    }
  }
}
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(112, 163, 132);
  background: linear-gradient(90deg, rgba(112, 163, 132, 1) 0%, rgba(148, 201, 169, 1) 34%, rgba(6, 115, 140, 1) 100%);
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.quiz-container {
  text-align: center;
  width: 70%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  margin: 20px auto;
  border-radius: 8px;
  box-sizing: border-box;
}

.question-section,
.result-section {
  margin-bottom: 20px;
}

.question-image,
.result-image {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.question-counter {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.answers-list {
  list-style: none;
  padding: 0;
}

.answer-button {
  background-color: #E98A15;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1.2em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.answers-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.madewith {
  font-size: 0.8em;
  color: #6f6f6f;
  margin-top: 20px;
  display: block;
}

.win {
  font-size: 1.2em;
  color: #06738c;
  font-family: "Pacifico", cursive;
  font-style: normal;
  text-decoration: none;
}

.finaleStateDescription {
  margin-top: 10px;
  font-size: 1.4em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 125%;
}

a {
  text-decoration: none;
}

h1 {
  color: #06738c;
  font-family: "Pacifico", cursive;
  font-style: normal;
}

h2 {
  color: #4c4c4c;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .quiz-container {
    width: 90%;
    /* Increase width for smaller screens */
  }

  .question-counter,
  .answers-list li {
    font-size: 1em;
    /* Adjust font size for readability */
  }

  .answer-button {
    padding: 8px 16px;
    /* Adjust button size */
    font-size: 1em;
    /* Make font smaller to fit smaller screens */
  }
}

@media (max-width: 480px) {
  .answers-section {
    flex-direction: column;
    /* Stack buttons vertically on very small screens */
  }

  .answer-button {
    width: 100%;
    /* Make buttons full width */
    margin: 5px 0;
    /* Adjust margin for stacked layout */
  }
}

@media (max-width: 768px) {

  .question-image,
  .result-image {
    max-width: 90%;
    /* Adjust max-width for smaller screens if needed */
  }
}

@media (max-width: 480px) {

  .question-image,
  .result-image {
    max-width: 100%;
    /* Full width on very small screens */
  }
}
</style>
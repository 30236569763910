<script setup>
import { ref, computed } from 'vue'
import QuizMain from './components/QuizMain.vue';
import WinCoupon from './components/WinCoupon.vue';
import MamsterSlots from './components/MamsterSlots.vue';

const routes = {
  '/': QuizMain,
  '/win': WinCoupon,
  '/mamsterslots': MamsterSlots
}

const currentPath = ref(window.location.hash)

window.addEventListener('hashchange', () => {
  currentPath.value = window.location.hash
  console.log(currentPath.value)
})

const currentView = computed(() => {
  return routes[currentPath.value.slice(1) || '/']
})
</script>

<template>
  <div id="app">
    <component :is="currentView" />
  </div>
</template>
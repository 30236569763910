<script>
export default {
  name: 'RandomString',
  data() {
    return {
      strings: ['Eine lange Mamstermassage von deinem Mamster', 'Ein Mamsterkaffee und ein Stück Kuchen mit deinem Mamster', 'Ein Abendessen mit deinem Mamster'],
      randomString: '',
    };
  },
  methods: {
    generateRandomString() {
      this.randomString = this.strings[Math.floor(Math.random() * this.strings.length)];
    },
  },
  mounted() {
    this.generateRandomString();
  },
};
</script>

<template>
    <div class="coupon-container">
        <h1>Du hast einen Mamster-Gutschein gewonnen!</h1>
        <div class="coupon">
            <p class="text">Mamster-<strong style="color: #06738c;">Gutschein</strong></p>
            <div class="decorative-line"></div>
            <p class="text">{{ randomString }}</p>
        </div>
        <p>Mache einen Screenshot, um deinen Coupon bei deinem Lieblingsmamster einlösen zu können!</p>
        <img width="200" src="@/assets/couponMamster.webp" />
    </div>
</template>

<style>
    body, html {
      background: rgb(112,163,132);
      background: linear-gradient(90deg, rgba(112,163,132,1) 0%, rgba(148,201,169,1) 34%, rgba(6,115,140,1) 100%);
    }

    .coupon-container {
        color: #f5f5f5;
        text-align: center;
        max-width: 450px;
    }

    .coupon {
        width: 300px;
        padding: 20px;
        background-color: #f5f5f5; /* Light gray background */
        border: 5px dashed #06738c; /* Dashed border */
        border-radius: 10px;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin: 20px auto; /* Add space around the coupon */
    }

    .text {
        font-size: 1.5em;
        color: #333;
        line-height: 1.5;
        font-family: "Pacifico", cursive;
        font-style: normal;
    }

    .decorative-line {
        border-top: 2px dashed #E98A15;
        margin: 10px 0;
    }
</style>
<template>
    <div class="slots-container">
        <h1>Einarmiger Mamster</h1>
        <p>Mamstermünzen: <strong>{{ score }}</strong></p>
        <div id="slot-machine">
            <div class="slots">
                <div id="s1" v-bind:class="{ 'winning': isWinning }" class="slot"><img class="slotimage"
                        v-bind:class="{ 'spinning': isSpinning }" v-bind:src="slot1.image" alt="slot1.name" /></div>
                <div id="s2" v-bind:class="{ 'winning': isWinning }" class="slot"><img class="slotimage"
                        v-bind:class="{ 'spinning': isSpinning }" v-bind:src="slot2.image" alt="slot2.name" /></div>
                <div id="s3" v-bind:class="{ 'winning': isWinning }" class="slot"><img class="slotimage"
                        v-bind:class="{ 'spinning': isSpinning }" v-bind:src="slot3.image" alt="slot3.name" /></div>
            </div>
            <button class="spin" @click="spin"><strong>Drehen</strong> (1x Mamstermünze)</button>
        </div>

        <div class="winning-message" v-if="score >= 30">
            <h2>Herzlichen Glückwunsch!</h2>
            <p><em>Du hast 30 Mamstermünzen erreicht!</em></p>
            <p>Du kannst deine Mamstermünzen jederzeit gegen Küsse bei deinem Mamster eintauschen!</p>
        </div>

        <p>
            <img src="@/assets/slotMamster.webp" width="100" />
        </p>
    </div>
    <br>
</template>

<script>
export default {
    data() {
        return {
            isSpinning: false,
            isWinning: false,
            score: 5,
            slotMamsters: [
                { name: 'S1', image: require('@/assets/slot1.png') },
                { name: 'S2', image: require('@/assets/slot2.png') },
                { name: 'S3', image: require('@/assets/slot3.png') },
            ],
            slot1: { name: 'S1', image: require('@/assets/slot1.png') },
            slot2: { name: 'S2', image: require('@/assets/slot2.png') },
            slot3: { name: 'S3', image: require('@/assets/slot3.png') },
        }
    },
    methods: {
        spin() {
            if (this.score < 1) {
                alert('Leider hast du keine Mamstermünzen mehr :(');
                return;
            } else {
                this.score--;
                this.isSpinning = true;
                setTimeout(() => {
                    this.slot1 = this.slotMamsters[Math.floor(Math.random() * this.slotMamsters.length)];
                    this.slot2 = this.slotMamsters[Math.floor(Math.random() * this.slotMamsters.length)];
                    this.slot3 = this.slotMamsters[Math.floor(Math.random() * this.slotMamsters.length)];
                    this.isSpinning = false;
                    this.checkScore();
                }, 1000);
            }
        },
        checkScore() {
            if (this.slot1.name === this.slot2.name && this.slot2.name === this.slot3.name) {
                this.score += 10;
                this.isWinning = true;
            } else {
                this.isWinning = false;
            }
        }
    }
}
</script>

<style scoped>
body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(112, 163, 132);
    background: linear-gradient(90deg, rgba(112, 163, 132, 1) 0%, rgba(148, 201, 169, 1) 34%, rgba(6, 115, 140, 1) 100%);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

h2 {
    font-size: 1.5em;
    margin: 0;
    color: #E98A15;
}

.slots-container {
    max-width: 350px;
    text-align: center;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
    margin: 20px auto;
    border-radius: 8px;
    box-sizing: border-box;
}

.slots {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.slot {
    width: 100px;
    height: 100px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slotimage {
    width: 100%;
}

.spin {
    padding: 10px 20px;
    font-size: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease;
}

@keyframes spin {
    0% {
        transform: translateY(0);
        transform: rotate(0deg);
    }

    50% {
        transform: translateY(10%);
        transform: rotate(45deg);
    }

    100% {
        transform: translateY(-10%);
        transform: rotate(-45deg);
    }
}

.spinning {
    animation: spin 1s linear infinite;
}

.winning-message {
    padding: 20px;
    margin-top: 20px;
    background-color: #06738c;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.winning {
    border: 5px solid green;
}
</style>